body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.search-field {
  width: auto;
  height: 36px;
  border-radius: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card-footer::after {
  display: none;
}

.card-footer {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

textarea {
  resize: none;
}
.sidebar-mini.layout-fixed.sidebar-collapse ul.nav.nav-pills.nav-sidebar.flex-column .collapse {
  display: none;
}
.sidebar-mini.layout-fixed.sidebar-collapse ul.nav.nav-pills.nav-sidebar.flex-column li.nav-item.menu-open:hover {
  pointer-events: none;
  cursor: none;
}
.sidebar-mini.layout-fixed.sidebar-collapse ul.nav.nav-pills.nav-sidebar.flex-column li.nav-item.menu-open{
  cursor:default !important;
}

.form-check {
  display: flex;
}

.profile_box {
  position: relative;
  display: inline-block;
}
.nav-item.menu-open a.nav-link p {
  display: contents !important;
}
.nav-item.menu-open a.nav-link p span {
  width: 80%;
  display: inline-table;
}

/* a.nav-link p {
  display: flow-root !important;
} */

.profile_box img {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  display: inline-block;
  object-fit: cover;
  border: 1px solid lightgrey !important;
}

.blog_form label, .select_list label {
  font-weight: 500;
  display: block;
  font-size: 14px;

  color: grey;
}

.select_list select {
  min-width: 240px;
  padding: 7px 5px;
  border-radius: 3px;
  font-size: 15px;
  border: 1px solid lightgray;
  background-color: white;
  outline: none !important;
}

.select_list {
  position: absolute;
  right: 0;
  top: 0
}

.blog_form .input100, .blog_form input {
  font-weight: bold;
  width: 100%;
  border: 1px solid lightgray;
  padding: 9px 5px;
  font-size: 15px;
  font-weight: 500 !important;
  display: block;
  outline: none;
}

.blog_form button {
  background: #3b7cfe;
  color: white;
  border: 0;
  padding: 11px 22px;
  border-radius: 5px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 25px;
}

.profile_box input {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.App {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

}

.LoginScreen {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;

}

.select-in {
  position: absolute;
  left: 10px;
  top: 50px;
  width: 120px;
  opacity: 0;
  height: 36px;
}

.LoginFormContainer {
  width: 50%;
  background-color: rgb(254, 254, 254);
}

.upload-btn {
  background: #007bff;
  border: none;
  width: 120px;
  padding: 7px 0;
  color: #fff;
  margin: 10px;
}

.formHeader {
  background-color: #dfe3e8 !important;
}

/* .sidebar-mini.sidebar-collapse .nav-sidebar .nav-link p {
  white-space: inherit !important;
  display: contents !important;
} */

/* .sidebar-mini .nav-sidebar, .sidebar-mini .nav-sidebar .nav-link, .sidebar-mini .nav-sidebar>.nav-header {
  white-space: inherit !important;
} */

/* .sidebar-mini.sidebar-collapse .nav-sidebar .nav-link p{
  white-space: break-spaces !important;
} */
.sidebar-mini .nav-sidebar, .sidebar-mini .nav-sidebar .nav-link, .sidebar-mini .nav-sidebar>.nav-header {
  display: block;
  /* height: 40px; */
}

.main-sidebar:hover .nav-link.active {
  height: auto !important;
  white-space: inherit !important;
}

.main-sidebar:hover .nav-link p {
  white-space: break-spaces !important;
}

.starata-list li {
  color: #494949;
}

.starata-list li i {
  color: #cecece;
}
.seereport-bt {
  background: #3b7cfe;
  padding: 8px 10px;
  display: block;
  width: fit-content;
  border-radius: 5px;
  margin: 10px;
  color: #fff;
  cursor: pointer;
}
.starata-list li {
  list-style: none;
  line-height: 32px;
  font-size: 14px;
}

.starata-list {
  padding-left: 17px;
}

i.fa.fa-circle.nav-icon.active {
  color: #3b7cfe;
}

.management-icon {
  float: right;
  line-height: 25px;
}

[aria-expanded="true"] .fa-angle-right.management-icon {
  display: none;
}

[aria-expanded="true"] .fa-angle-down.management-icon {
  display: block;
}

.fa-angle-down.management-icon {
  display: none;
}

.nav-item.menu-open {
  cursor: pointer;
}

table {
  width: 100%;
}

table, tr, td, th {
  border: 1px solid #7b7b7b;
  vertical-align: middle;
  padding: 10px;
}

.login-main-div {
  background: #1D83FF;
}

.login-main-div .card-header.formHeader {
  background: white !important;
  text-align: center !important;

}

.login-main-div .card-title {
  font-size: 25px;
  color: black;
  width: 100%;
  text-align: center;
}

.LoginFormContainer {
  border-radius: 10px;
  overflow: hidden;
}

.user-list {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 4px #d8d8d8;
  border-top: 4px solid #3b7cfe !important;
  position: relative;
  border: 1px solid #f0f0f0;
  margin: 10px 10px 10px 0;
}

.view-all-bt {
  display: block;
  margin: 0 auto;
  text-align: center;
  background: #3b7cfe;
  width: 100px;
  margin-top: 10px;
  border-radius: 5px;
  padding: 6px 0;
  color: #fff;
}

.more-info-bt {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  background: #faf7f7 !important;
}

.user-list h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.user-list p {
  margin-bottom: 25px;
}

.more-info-bt {
  background: none;
  border: none;
  color: #3b7cfe;
}

.user-box-list {
  background: white;
  padding: 10px;
  border-radius: 5px;
}

.user-panel.pt-3.pb-3.d-flex.justify-content-center {
  border-bottom: 1px solid gainsboro !important;
}

aside.main-sidebar.sidebar-dark-primary.elevation-4 {
  background: #fff !important;
}

.main-logo {
  box-shadow: none !important;
  width: 100% !important;
  cursor: pointer;
}

.user-search {
  width: 100%;
  padding-bottom: 11px;
}

.user-search input {
  width: 89%;
  border: 1px solid #e8e8e8;
  border-radius: 5px 0 0 5px;
  height: 32px;
}

.user-search button {
  background: #3b7cfe;
  border: none;
  color: #fff;
  border-radius: 0 5px 5px 0;
  height: 32px;
}

.user-scroll {
  max-height: 350px;
  overflow: auto;
}

.col-form-label {
  width: -webkit-inline-box !important;
}

.view-user-left img {
  width: 190px;
  height: 190px;
  object-fit: cover;
}

.view-user-left {
  width: 200px;
  height: 200px;
  border: 1px solid #b9b9b9;
  padding: 5px;
}

.user-right-side h5 {
  font-size: 18px;
  font-weight: 400;
  color: black;
}

.user-right-side h5 span {
  color: #7d7d7d;
  font-weight: 400;
  margin-left: 13px;
}

.switch.btn {
  min-width: 6.50rem !important;
}

.upload-text {
  margin-top: 10px;
  margin-left: 10px;
}

.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #000000bf;
  top: 0;
  z-index: 1047;
}

.loader div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.edit_bot_img.m-auto {
  width: 160px;
  position: relative;
}

.change-profile-input {
  position: absolute;
  height: 160px;
  width: 160px;
  left: 0;
  border-radius: 50%;
  opacity: 0;
  cursor: pointer;
}

.select-type .select_list {
  position: inherit;
  right: 0;
  top: 0;
}

.select-type .select_list select {
  min-width: 436px;
  padding: 11px 8px;
}
.main-sidebar .nav {
  max-height: 600px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (max-width:576px) {
  .select_list {
    position: unset;
    margin-top: 20px;
  }
}

.sub-select-category {
  width: 100%;
  margin: 0;
  height: 45px;
  border-color: #00000026;
}

.add-catergory-form input {
  height: 45px;
  width: 100%;
  font-size: 14px;
  padding: 0 10px;
  color: #6e777f;
  border: 1px solid #ced4da !important;
  border-radius: 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #007bff;
}

input:focus+.slider {
  box-shadow: 0 0 1px #007bff;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}



/* ============================== ABOUT US ==================================== */
.text-field {
  width: 100%;
  border-radius: 12px;
  border: 1px solid #d7d7d7;
  padding: 15px 12px;
  background: transparent;
}

.date-picker .text-field {
  padding: 11px 20px;
}

.main-search .search-field {
  width: 100%;
}

.btn-box {
  display: flex;
  justify-content: space-between;
  margin-top: 37px;
}

.search-main {
  margin-top: 32px;
}

.btn-box button {
  width: 47%;
}
.export-area {
  display: flex;
  justify-content: flex-end;
}
.export-area p{
  margin-right: 10px;
}
.edit-user-form input {
  width: 100%;
  padding: 5px;
  border: 1px solid #e2e2e2;
}
.edit-user-left img {
  width: 100%;
  border: 4px solid white;
  object-fit: cover;
  height: 240px;
}
.upload-img {
  position: absolute;
  left: 0;
  top: 0;
  height: 236px;
  opacity: 0;
  cursor: pointer;
}
.sub-bt {
  background: #007bff;
  border: none;
  color: #fff;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 16px;
}

.search-field {
  width: 25%;
  height: 36px;
  border-radius: 36px;
  padding: 23px 39px 20px 20px;
  background: transparent;
  border: 2px solid #adadad;
}
.new-ql {
  width: max-content;
}
.new-ql .blog_form button{
  margin-top: 0 !important;
}
.new-ql span.ql-formats button {
  margin-top: 0;
}
.new-ql .ql-editor {
  min-height: 150px;
  max-width: 550px;
  max-height: 200px;
  overflow: auto;
}
.about-img img {
  width: 500px;
  height: 400px;
}

.about-img {
  position: relative;
}

.abt-img-btn .upload-file {
  position: absolute;
  bottom: -40px;
  left: 0%;
  width: 133px;
  height: 50px;
  opacity: 0;
  background: #000;
  cursor: pointer;
  z-index: 9999;
}

.choose-area {
  padding: 62px 0 0 0;
}

.upload-butn {
  position: absolute;
  bottom: -40px;
  left: 0;
  background: transparent;
  outline: none;
  border: none;
  padding: 12px;
  border: 2px solid #007bff;
  border-radius: 12px;
  color: #007bff;
  font-weight: bold;
}

.curious_set {
  padding: 45px 30px;
}

.set_curious_block {
  margin-top: 35px;
}

.aboutus-curious-no {
  border-radius: 50%;
  color: #fff;
  font-family: Poppins_bold;
  width: 78px;
  height: 78px;
  background-color: #1d83ff;
}

.aboutus-curious-no-text {
  margin-top: 2px;
  font-size: 30px;
  line-height: 42px;
}

.carious_card_block {
  padding: 32px 0 0;
}

.user_intro {
  position: relative;
}

.aboutus-userinfo-card {
  object-fit: fill;
  border-left: 35px solid #1d83ff;
  border-radius: 30px;
  padding: 32px 40px;
  margin: 20px 0 20px 20px;
  box-shadow: 0 30px 40px rgba(0, 0, 0, .05);
  height: 321px;
  background-color: #fff;
}

.text-field:focus-visible {
  background: #fff;
  outline: #fff !important;
}

/* ============================================================================ */